var cart_timer_interval = null;
var startingTime = 0;
PROD_DEPLOYMENT_ID = "0d59fd8b-3225-4b9f-aae1-314aa7da499e";
STAGE_DEPLOYMENT_ID = "3636136d-d5ef-43a1-9004-633fa4a2163d";

class HeaderTemplateGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('HeaderTemplateGlo before load');

		this.data = {
			...this.data,
			loggedIn: false,
		};
	}

	afterLoad() {
		// console.log('HeaderTemplateGlo after load');

		this.$el = $(this);

		// Set top position of menu
		this.adjustMenuTop();
		this.bindEvents();
		this.getCartCount();
		this.customizeGloNav();
		this.addSafariFixes();
		this.setAccount();
		this.triggerBlogViewEvent();
		this.handleCartTimer();
		this.handleUserReturnsOnSession();
		this.addChatScriptIfAgegateTrue();
	}

	triggerBlogViewEvent() {
		window.location.href.includes("blog") > -1
			? DataLayerUtils.trackGenericEvent("BlogViews")
			: null;
	}

	async handleCartTimer() {
		if (localStorage.getItem("cart-timer")) {
			let cartId = Utils.getCookie("commerce-cart-id");
			if (cartId) {
				const getCart = await Commerce.getCart(cartId);
				if (getCart && getCart.data && getCart.data.cart) {
					if (getCart.data.cart.cart_expiry.expires_at != null) {
						if (cart_timer_interval) {
							clearInterval(cart_timer_interval);
						}
						startingTime = localStorage.getItem("cart-timer");
						cart_timer_interval = setInterval(function () {
							let minutes = Math.floor(startingTime / 60);
							let seconds = startingTime % 60;

							let timeFormatted = `${minutes
								.toString()
								.padStart(2, "0")}:${seconds
								.toString()
								.padStart(2, "0")}`;
							$(".bat-header-cart-timer").text(
								`Completa l'ordine in ${timeFormatted}`
							);
							$(".bat-header-timer").css("display", "block");
							window.innerWidth < 500 &&
							$(".bat-header-loyalty-info").css(
								"display",
								"none"
							);
							startingTime = startingTime - 1;
							localStorage.setItem("cart-timer", startingTime);

							if (startingTime < 0) {
								clearInterval(cart_timer_interval);
								$(".bat-header-cart-timer").text("");
								window.innerWidth < 500 &&
								$(".bat-header-loyalty-info").css(
									"display",
									"flex"
								) &&
								$(".bat-header-timer").css(
									"display",
									"none"
								);
							}
						}, 1000);
					} else {
						clearInterval(cart_timer_interval);
					}
				}
			}
		}
	}

	handleUserReturnsOnSession() {
		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "visible") {
				this.handleCartTimer();
			}
		});
	}

	addChatScriptIfAgegateTrue() {
		const agegate = Utils.getCookie("agegate");
		if (agegate === "true") {
			let url = window.location.href;

			(function (g, e, n, es, ys) {
				g["_genesysJs"] = e;
				g[e] =
					g[e] ||
					function () {
						(g[e].q = g[e].q || []).push(arguments);
					};
				g[e].t = 1 * new Date();
				g[e].c = es;
				ys = document.createElement("script");
				ys.async = 1;
				ys.src = n;
				ys.charset = "utf-8";
				document.head.appendChild(ys);
			})(
				window,
				"Genesys",
				"https://apps.mypurecloud.ie/genesys-bootstrap/genesys.min.js",
				{
					environment: "prod-euw1",
					deploymentId: url.includes("uat")
						? STAGE_DEPLOYMENT_ID
						: PROD_DEPLOYMENT_ID,
				}
			);
		}
	}

	adjustMenuTop() {
		const headerHeight = this.$el
			.find(".bat-header-wrapper header")
			.outerHeight();
		const $mobileMenu = this.$el.find(".bat-header-menu");

		/* eslint-disable-next-line no-undef */
		const viewportSize = Utils.getViewportSize();

		if (viewportSize.viewportWidth <= 1024) {
			$mobileMenu.css("top", headerHeight);
		} else {
			$mobileMenu.attr("style", "");
		}
	}

	addSafariFixes() {
		if (/iPhone/.test(navigator.userAgent))
			this.classList.add("safari-fix");
	}

	async bindEvents() {
		let notEmpty = Commerce.getCartQuantity() > 0;
		let isCartOpen = false;
		const $account = this.$el.find(".bat-header-account");
		const $accountButton = this.$el.find(".bat-header-account-link");
		const $accountMenu = this.$el.find(".bat-header-account-menu");
		const $linkMenus = this.$el.find(
			".bat-navigation-group-list-item--menu"
		);
		const $mobileMenu = this.$el.find(".bat-header-menu");
		const $mobileMenuButton = this.$el.find(".bat-header-menu-button");

		// Resize event to set menu top
		$(window).resize(() => {
			this.adjustMenuTop();
		});

		if (this.data.sticky == "true") {
			const headerSticky = this.$el.find(".bat-header-gloit");
			const thirdSection = headerSticky.find(".third-section");
			if (thirdSection.length > 0) {
				const sectionEl = thirdSection[0];
				$(document).scroll(() => {
					if (headerSticky.hasClass("uk-sticky-below")) {
						if (
							sectionEl.getAttribute("data-collapsed") !== "true"
						) {
							const sectionHeight = sectionEl.scrollHeight;
							const elementTransition =
								sectionEl.style.transition;
							sectionEl.style.transition = "";

							requestAnimationFrame(() => {
								sectionEl.style.height = sectionHeight + "px";
								sectionEl.style.transition = elementTransition;

								requestAnimationFrame(() => {
									sectionEl.style.height = 0 + "px";
								});
							});

							sectionEl.setAttribute("data-collapsed", "true");
						}
					} else {
						if (
							sectionEl.getAttribute("data-collapsed") === "true"
						) {
							sectionEl.setAttribute("data-collapsed", "false");
							const sectionHeight = sectionEl.scrollHeight;
							sectionEl.style.height = sectionHeight + "px";
						}
					}
				});
			}
		}

		$accountButton.click((e) => {
			e.preventDefault();
			if (!$accountMenu.hasClass("open")) {
				$accountMenu.addClass("open");
				$("body").addClass("account-open");
			} else {
				$accountMenu.removeClass("open");
				$("body").removeClass("account-open");
			}
		});

		// menu button
		$mobileMenuButton.click((e) => {
			$(e.currentTarget).toggleClass("active");
			$mobileMenu.toggleClass("open");
			$account.removeClass("open");

			// Close Cart
			if (!this.$el.find(".bat-minicart-gloit").hasClass("d-none")) {
				this.$el.find(".bat-minicart-gloit").addClass("d-none");
				isCartOpen = false;
			}

			if ($mobileMenu.hasClass("open")) {
				/* eslint-disable-next-line no-undef */
				Utils.lockBody();
			} else {
				/* eslint-disable-next-line no-undef */
				Utils.unlockBody();
			}
		});

		// close dropdown menu's when clicked outside of nav
		$(document).click(() => {
			if ($linkMenus.hasClass("open")) {
				$linkMenus.removeClass("open");
			}

			if ($account.hasClass("open")) {
				$account.removeClass("open");
			}

			if (!this.$el.find(".bat-minicart-gloit").hasClass("d-none")) {
				this.$el.find(".bat-minicart-gloit").addClass("d-none");
				isCartOpen = false;
			}
		});

		// link menus
		$.map($linkMenus, ($menu) => {
			// close other link menu's if open
			$($menu).click((e) => {
				if ($linkMenus.siblings().hasClass("open")) {
					$linkMenus.siblings().removeClass("open");
					$($menu).toggleClass("open");
					e.stopPropagation();
				}
			});

			// find links and attach click event for sub menus
			$($menu)
				.find("a.bat-navigation-group-list-item-link")
				.click((e) => {
					e.preventDefault();
					e.stopPropagation();
					$.map($linkMenus, ($temp_menu) => {
						if (
							$($temp_menu).hasClass("open") &&
							!$($temp_menu).is($menu)
						) {
							$($temp_menu).removeClass("open");
						}
					});
					$($menu).toggleClass("open");
				})
				.mouseenter(() => {
					$($menu).removeClass("close");
				});

			$($menu)
				.find(".bat-navigation-sub-group-close")
				.click(() => {
					$($menu).addClass("close");
					$($menu).removeClass("open");
				});
		});

		this.$el.find(".bat-header-cart").click((e) => {
			e.preventDefault();
			e.stopPropagation();
			document
				.querySelector(".bat-minicart-gloit")
				.classList.remove("d-none");
			isCartOpen = true;
		});

		// Listen for cart action
		$(window).on("cart-action", () => {
			this.$el
				.find(".bat-header-cart .bat-header-cart-count")
				.addClass("active")
				.addClass("loading");
		});

		// Listen for cart updates
		$(window).on("cart-update mixed-cart", async () => {
			// Minicart events
			notEmpty = Commerce.getCartQuantity() > 0;

			if (notEmpty) {
				this.$el.find(".bat-header-cart").removeClass("cartEmpty");
			} else {
				this.$el.find(".bat-header-cart").addClass("cartEmpty");
				this.$el
					.find(".bat-header-cart button")
					.attr("disabled", "disabled");
			}

			if (notEmpty) {
				this.$el.find(".bat-header-cart").removeClass("cartEmpty");
				this.$el.find(".bat-header-cart button").removeAttr("disabled");

				// update red bubble with new cart item count and show
				this.$el
					.find(".bat-header-cart .bat-header-cart-count span")
					.text(this.getCartCount());
				this.$el
					.find(".bat-header-cart .bat-header-cart-count")
					.addClass("active")
					.removeClass("loading");
			} else {
				this.$el.find(".bat-header-cart").addClass("cartEmpty");
				this.$el
					.find(".bat-header-cart button")
					.attr("disabled", "disabled");
				this.$el
					.find(".bat-header-cart .bat-header-cart-count")
					.removeClass("active")
					.removeClass("loading");
			}
		});

		window.addEventListener("login-action", () => {
			this.setAccount();
		});
	}

	async setAccount() {
		this.data.loggedIn = Utils.getCookie("commerce-auth-verify") === "true";
		const $account = this.$el.find(".bat-header-account");
		const $mobileMenu = this.$el.find(".bat-header-menu");
		const $mobileMenuButton = this.$el.find(".bat-header-menu-button");

		// Account
		if (this.data.loggedIn) {
			$account.addClass("authenticated");
			$account
				.find(".bat-header-account-link a")
				.off("click")
				.on("click", (e) => {
					e.preventDefault();
					//e.stopPropagation();
					$account.toggleClass("open");
					$mobileMenu.removeClass("open");
					$mobileMenuButton.removeClass("active");
				});

			$account.find(".logout-cta").on("click", (e) => {
				Commerce.logoutCustomer();
				window.location.reload();
			});

			const customerResponse = await Commerce.getCustomerDetails();
			const customer = customerResponse.data.customer;
			if (customer.global_loyalty_optin === "1") {
				const loyaltyInfo = await Commerce.loyaltyUserInfo();
				if (
					loyaltyInfo &&
					loyaltyInfo.data &&
					loyaltyInfo.data.loyaltyUserInfo
				) {
					const loyaltyUserInfo = loyaltyInfo.data.loyaltyUserInfo;
					const loyaltyBox = this.$el.find(
						".bat-header-loyalty-info"
					);
					if (loyaltyBox.length > 0) {
						const userName = loyaltyBox.find(".account-name");
						const userTier = loyaltyBox.find(
							".account-loyalty-title"
						);
						const circle2 = loyaltyBox.find(
							".small-circle.circle-2"
						);
						const circle3 = loyaltyBox.find(
							".small-circle.circle-3"
						);

						const shortName =
							customer.firstname[0] + customer.lastname[0];
						userName.text(shortName);
						userTier.text(loyaltyUserInfo.user_tier);
						if (
							loyaltyUserInfo.user_tier === "trendsetter" ||
							loyaltyUserInfo.user_tier === "fresh gloer"
						) {
							if (window.innerWidth <= 991) {
								$(".account-loyalty-circles").css(
									"display",
									"none"
								);
								userTier.text("fresh gloer");
							} else {
								$(".account-loyalty-circles").css(
									"display",
									"flex"
								);
								userTier.text("fresh gloer");
							}
							$(window).on("resize", () => {
								if (window.innerWidth <= 991) {
									$(".account-loyalty-circles").css(
										"display",
										"none"
									);
									userTier.text("fresh gloer");
								} else {
									$(".account-loyalty-circles").css(
										"display",
										"flex"
									);
									userTier.text("fresh gloer");
								}
							});
						} else if (
							loyaltyUserInfo.user_tier === "hero" ||
							loyaltyUserInfo.user_tier === "expert gloer"
						) {
							circle2.addClass("circle-orange");
							if (window.innerWidth <= 991) {
								$(".account-loyalty-circles").css(
									"display",
									"none"
								);
								userTier.text("expert gloer");
							} else {
								$(".account-loyalty-circles").css(
									"display",
									"flex"
								);
								userTier.text("expert gloer");
							}
							$(window).on("resize", () => {
								if (window.innerWidth <= 991) {
									$(".account-loyalty-circles").css(
										"display",
										"none"
									);
									userTier.text("expert gloer");
								} else {
									$(".account-loyalty-circles").css(
										"display",
										"flex"
									);
									userTier.text("expert gloer");
								}
							});
						} else if (
							loyaltyUserInfo.user_tier === "leader" ||
							loyaltyUserInfo.user_tier === "super gloer"
						) {
							circle2.addClass("circle-orange");
							circle3.addClass("circle-orange");
							if (window.innerWidth <= 991) {
								$(".account-loyalty-circles").css(
									"display",
									"none"
								);
								userTier.text("super gloer");
							} else {
								$(".account-loyalty-circles").css(
									"display",
									"flex"
								);
								userTier.text("super gloer");
							}
							$(window).on("resize", () => {
								if (window.innerWidth <= 991) {
									$(".account-loyalty-circles").css(
										"display",
										"none"
									);
									userTier.text("super gloer");
								} else {
									$(".account-loyalty-circles").css(
										"display",
										"flex"
									);
									userTier.text("super gloer");
								}
							});
						}
						loyaltyBox.removeClass("uk-hidden");
					}
				}
			}
		} else {
			$account.removeClass("authenticated");
			/*$account
				.find('.bat-header-account-link a')
				.attr(
					'href',
					`${$account.find('a').attr('href')}?resource=${
						window.location.pathname
					}`
				);*/
		}
	}

	getCartCount() {
		const cartCount = Commerce.getCartQuantity();
		const $cartEl = this.$el.find(".bat-header-cart a");
		let bubbleSize;

		// Class modifier switch
		switch (true) {
			case cartCount > 99:
				bubbleSize = "large";
				break;
			case cartCount > 9:
				bubbleSize = "medium";
				break;
			default:
				bubbleSize = "small";
		}

		if (cartCount && $cartEl) {
			const $cartBubble = this.$el.find(".bat-header-cart-count");
			$cartBubble.addClass(bubbleSize);
			$cartBubble.html(`<span>${cartCount}</span>`);

			setTimeout(() => {
				this.$el.find(".bat-header-cart-count").addClass("active");
			}, 1000);
		}
	}

	/* eslint-disable-next-line class-methods-use-this */
	customizeGloNav() {
		const links = [
			document.querySelector(".bat-header-gloit .bat-header-support a"),
			document.querySelector(".bat-header-gloit .bat-header-stores a"),
		];
		const navMenu = document.querySelector(
			".bat-header-gloit .bat-navigation-group-list"
		);

		// get icon links, clone into mobile nav, and hide on desktop
		links.forEach((link) => {
			if (link) {
				const li = document.createElement("li");
				li.className =
					"bat-navigation-group-list-item bat-navigation-group-list-item--velo-mobile";
				li.innerHTML = link.outerHTML;
				navMenu.appendChild(li);
				link.parentElement.parentElement.classList.add(
					"bat-header--velo-desktop"
				);
			}
		});
	}

	beforeUpdate() {
		// console.log('HeaderTemplateGlo before update');
	}

	afterUpdate() {
		// console.log('HeaderTemplateGlo after update');
	}

	unload() {
		// console.log('HeaderTemplateGlo after unload');
	}
}

!customElements.get("bat-header-gloit") &&
customElements.define("bat-header-gloit", HeaderTemplateGloIT);
